import RailsUJS from "@rails/ujs";

const enableUjsButtons = () => {
  const buttons = Array.from(document.querySelectorAll('.ujs-wait'));
  buttons.forEach(button => {
    button.classList.remove('ujs-wait');
  });
};

if (!window._rails_loaded) {
  RailsUJS.start();
  enableUjsButtons();
}
